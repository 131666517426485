$grey-1: #f8f8f8;
$grey-2: #dedede;
$white: #ffffff;
$black: #000000;
$yellow: #ffc524;
$green: #63d9a0;
$blue: #82e6e6;
$blue-light: #cff2f2;
$orange: #ff8e4f;

$container: 70vw;
$container-mob: 90vw;

$content-padding: 6vw;
$footer-height: 20vw;

$matter: "Matter", sans-serif;

$px14: 3vw;
