.focus {
	position: relative;
	height: 100vh;
	flex-direction: column;

	&__content {
		overflow-y: scroll;
		padding: 2vw $content-padding calc(#{$footer-height} + 7vw);

		&-main {
			margin-bottom: 3vw;
		}
	}
}
