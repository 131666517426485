.profile {
	position: relative;
	height: 100vh;
	flex-direction: column;

	$header-height: 33vw;

	&__header {
		height: $header-height;

		&-content {
			position: relative;
			background-color: $white;
		}

		&-hamburger {
			width: 6vw;
			position: absolute;
			top: 8vw;
			right: 6vw;
		}
	}

	&__content {
		overflow-y: scroll;
		padding: calc(#{$header-height + 2vw}) 0 $footer-height;
	}
}
