.gym {
	position: relative;
	height: 100vh;
	flex-direction: column;

	&__header {
		&-button {
			width: 8vw;
			position: absolute;
			right: $content-padding;
			top: 8vw;
		}
	}

	&__content {
		overflow-y: scroll;
		padding: 2vw $content-padding $footer-height;

		&-main {
			margin-bottom: 10vw;
		}

		&-info {
			width: 6vw;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}
