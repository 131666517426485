.modal {
	z-index: 10;

	&__backdrop {
		background-color: rgba(0, 0, 0, 0.5);
	}

	&__modal {
		width: 100%;
		height: 100vw;
	}

	&__demo,
	&__tactile {
		width: 80%;
	}

	&__profileMenu {
		position: absolute;
		left: 0;
		background-color: $white;
		border-top-left-radius: 3vw;
		border-top-right-radius: 3vw;

		&-holder {
			width: 80%;
		}

		&-item {
			&--disable {
				opacity: 0.4;
			}
		}
	}

	&__scheduleTime {
		position: absolute;
		left: 0;
		height: 70vw;

		&-button {
			padding: 0 3vw 5vw;
		}

		&-item {
			background-color: $white;
			padding: 4vw 0;
		}
	}

	$modal-height: 85vh;
	$player-height: 75vw;

	&__audioPlayer {
		position: absolute;
		left: 0;
		height: $modal-height;
		background-color: $yellow;
		border-top-left-radius: 4vw;
		border-top-right-radius: 4vw;

		&-image {
			flex-grow: 1;

			img {
				width: 20vmax;
			}
		}

		&-container {
			width: 100%;
		}

		&-holder {
			width: 100%;
			height: $player-height;
			padding: 0 10vw;
			background-color: $white;
		}

		&-item {
			background-color: $white;
		}

		&-button {
			margin: 7vw 0 4vw;

			&--disabled {
				opacity: 0.3;
			}
		}

		&-winner {
			&--gif {
				max-height: 30vh;
				object-fit: contain;
			}
		}
	}

	&__reps {
		$repsSize: 20vw;

		width: $repsSize;
		height: $repsSize;
		position: absolute;
		bottom: calc(#{$modal-height} - #{$repsSize});
		left: 2vw;

		&-progress {
			width: calc(#{$repsSize + 1vw});
			height: calc(#{$repsSize + 1vw});

			&--circle {
				transition: stroke-dashoffset 0.15s;
				transform: rotate(-90deg);
				transform-origin: 50% 50%;
			}
		}

		&-count {
			position: absolute;
			top: 10.6vw;
			left: 6.6vw;
			font-size: $px14;
			text-align: center;

			&--winner {
				width: 5vw;
				top: 8.6vw;
			}
		}
	}

	&__gymTactile {
		background-color: $green;
		height: 85vh;

		.modal__audioPlayer-image {
			height: max(20vh, 30vh);

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-top-left-radius: 4vw;
				border-top-right-radius: 4vw;
			}
		}

		.modal__reps {
			&-count {
				color: $white;
			}
		}
	}

	&__audiobook {
		background-color: $blue-light;
	}

	&__modulesVideo {
		$modal-height: 100vh;

		position: absolute;
		left: 0;
		height: $modal-height;
		background-color: $black;

		&-holder {
			height: $modal-height;
		}

		video {
			width: 100%;
		}
	}
}
