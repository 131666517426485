.player {
	&__button {
		$button-size: 8vw;

		position: absolute;
		width: $button-size;
		height: $button-size;
		bottom: $button-size;
		padding: 0;
		background-color: transparent;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.controls {
		position: relative;
	}

	.bar {
		user-select: none;
		width: 96%;
		display: flex;
		align-items: center;

		&__time {
			color: $black;
			font-size: $px14;
			width: 7vw;
			height: 2.5vw;
		}

		&__progress {
			flex: 1;
			border-radius: 0.5vw;
			margin: 0 3vw;
			height: 1vw;
			display: flex;
			align-items: center;
			cursor: pointer;

			&-knob {
				position: relative;
				height: 2.5vw;
				width: 2.5vw;
				border-radius: 50%;
				background-color: $black;
			}
		}
	}
}
