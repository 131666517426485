.modules {
	position: relative;
	height: 100vh;
	flex-direction: column;

	$header-height: 38vw;

	&__header {
		height: $header-height;
	}

	&__content {
		overflow-y: scroll;
		padding: calc(#{$header-height + 5vw}) $content-padding $footer-height;

		&-module {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 3vw;

			a {
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
