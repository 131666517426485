.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	height: $footer-height;
	background-color: $white;
	border-top: solid 0.02vw $grey-2;

	&__navs {
		&-element {
			opacity: 0.3;
		}

		&-current {
			opacity: 1;
		}
	}
}
