.welcome {
	position: relative;
	height: 100vh;
	flex-direction: column;

	$header-height: 12vw;

	&__header {
		height: $header-height;
		padding-top: 4vw;
		background-color: $grey-1;

		&-button {
			width: 5vw;
			position: absolute;
			top: 3vw;
			left: 3vw;
			padding: 2vw;
			box-sizing: content-box;
		}
	}

	&__content {
		width: 100%;
		overflow-y: scroll;
		padding-top: calc(#{$header-height + 2vw});

		&-main {
			margin-bottom: 3vw;
		}

		&-item {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
