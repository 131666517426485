.community {
	position: relative;
	height: 100vh;
	flex-direction: column;

	&__content {
		overflow-y: scroll;

		&-rest {
			padding-bottom: $footer-height;
		}
	}
}
