.settings {
	position: relative;
	height: 100vh;
	flex-direction: column;

	$header-height: 11vw;

	&__header {
		height: $header-height;
		padding-top: 4vw;
		background-color: $grey-1;
		z-index: 1;

		&-button {
			width: 5vw;
			position: absolute;
			top: 3vw;
			left: 3vw;
			padding: 2vw;
			box-sizing: content-box;
		}
	}

	&__content {
		width: 100%;
		overflow-y: scroll;
		padding: calc(#{$header-height}) 0 calc(#{$footer-height} + 7vw);

		&-main {
			margin-bottom: 3vw;
		}

		&-item {
			width: 100%;

			&--disabled {
				opacity: 0.4;
			}
		}

		a {
			padding: 0;
		}
	}
}
