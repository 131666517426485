* {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	overflow-x: hidden;
	background-color: $grey-1;
	min-height: 100vh;
	min-width: 100vw;
	font-family: $matter;
}

img {
	width: 100%;
}

a:not(.page404__link) {
	text-decoration: none;
	width: 100%;
	padding: 1.5vw 0.5vw;
	color: inherit;
	cursor: default;
}

p {
	margin: 0.5vw 0;
}

h1 {
	margin: 0;
}

h2 {
	margin: 0;
}

h3 {
	margin: 0;
}

button {
	outline: none;
	border: none;
	padding: 1.5vw 2.5vw;
	cursor: pointer;
}

svg {
	display: block;
	width: 100%;
	height: 100%;
}

iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.h-full {
	height: 100%;
}

.w-full {
	width: 100%;
}

.wh-full,
.hw-full {
	width: 100%;
	height: 100%;
}

.overflow-x {
	overflow-x: hidden;
}

.overflow-y {
	overflow-y: hidden;
}

.overflow {
	overflow: hidden;
}

.inline {
	display: inline !important;
}

.hidden {
	display: none !important;
}

.desktop {
	@include mobile {
		display: none !important;
	}
}

.mobile {
	@include desktop {
		display: none !important;
	}
}

.desktop-hidden {
	@include desktop {
		display: none !important;
	}
}

.mobile-hidden {
	@include mobile {
		display: none !important;
	}
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
	top: 0;
	left: 0;
}

[class*="container"] {
	width: $container;

	@include mobile {
		width: $container-mob;
	}
}
